.container {
    padding: 2% 10%;
}

.container h2 {
    font-size: 1.8rem;
}

i {
    font-size: 22px;
}

.gif {
    width: 70px;
    padding: 15% 0 15% 0;
}

.gif-container {
    height: 86px;
}

.gif-container-bigger {
    height: 486px;
    text-align: center;
    padding-top: 1.5rem;
}

.gif-bigger {
    width: 100%;
    height: 100%;
    padding: 15% 0 15% 0;
}

.gif-container-bigger i {
    font-size: 15px;
}

.gif-container-bigger i h2 {
    font-size: 1.5rem;
    padding-bottom: 1.5rem;
}